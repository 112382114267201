#cont-modal{
  position: fixed;
  z-index: 10;
  top: 0;
  height: 100%;

}
.modal-container{
  position: absolute;
  z-index: 2;
  top: 0;
  width: 100vw;
  height: 100%;
  max-width: 420px;
  left: 50vw;
  transform: translate(-50%,0);

  .sombra-modal{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.5);
    z-index: 0;
  }

  .masOscuro{
    background-color: rgba(0,0,0,.8);
  }

  .card{
    width: 98%;
    max-width: 380px;
    position: relative;
    box-shadow: 0 1px 3px rgba(0,0,0,.3);
    opacity: 0;
    margin: auto;
    max-height: calc(100vh - 100px);
    transform: scale(.95);
    overflow: hidden auto;
    transition: all 500ms cubic-bezier(0.190, 1.000, 0.220, 1.000);

    .close{
      font-size: 14px;
      position: absolute;
      top: .5em;
      right: .5em;
      cursor: pointer;
    }
  }
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: all 500ms cubic-bezier(0.075, 0.820, 0.165, 1.000);
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: all 500ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
}

.fade-enter-done{
  .card{
    opacity: 1;
    transform:scale(1);
  }
}