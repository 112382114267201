@import "components/modal/modal";


:root{
  --color-theme         : #080035;
  --grey-1         : #F1F1F1;
  --grey-2         : #cbcbcb;
  --color-theme-light   : #443671;
  --color-theme-orange  : #F54E00;
  --title-theme         : 'Kanit', sans-serif;
  --text-theme          : 'Open Sans', sans-serif;
  --transiton           : all 500ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
}

.ct  {color:var(--color-theme)}
.ct2 {color:var(--color-theme-light)}
.cto {color:var(--color-theme-orange)}

.ft{font-family: var(--title-theme)}


body,html,#root {
  height: 100%;
  width: 100%;
  font-family: var(--text-theme);
  font-size: 16px;
  line-height: 1.3em;
  color:var(--color-theme);
  background: #f2f2f200;

}

.main-container{
  height: 100%;
}

.wc{width: 100%;}
.ro{border: 1px solid red;}


small.error,.errortext{color: red;line-height: 1.4em; display: inline-block; font-size: 12px;padding-top: 5px}

.ReactCollapse--collapse {
  transition: var(  --transiton   );
}

.cursor-default {
  cursor: default !important;
}
